import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/education.jpg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import EducationImages from "../../components/applicationImageComponents/educationImages"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const EducationPage = () => {
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Education" description="" />

      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Education</h1>
          <p>
            Educational buildings, whether for Universities or K-12 Grade
            schools meet the challenges of scrubbing and sweeping on a daily
            basis. Often asked to scrub more square footage each year, with
            smaller janitorial staffs, many are looking to the compact rider
            scrubbers and and rider sweepers built by Factory Cat. Some of the
            modern schools have a multitude of complex floor coverings, ranging
            from artificial turf and rubber gym floors to ceramic tile and even
            marble.
          </p>
          <h2>We Provide the Best for All Needs:</h2>
          <ul>
            <li>
              Unlike traditional Sweeper/Scrubber units, these cylindrical
              machines can operate and sweep through a wet surface.
            </li>
            <li>
              The best solution for customers with such diverse cleaning needs
              is often a range of equipment: walk behind and rider floor
              scrubbers and sweepers.
            </li>
            <li>
              Factory Cat's line of equipment has the industry's greatest Parts
              Commonality and with similar controls on the scrubbers and
              burnishers, training new operators is quite simple.
            </li>
          </ul>
          <ApplicationQuote quote="FactoryCat scrubbers are great for ceramic tile, gym floors, or for removing finish/wax chemical free from terrazzo or VCT. -JC" />
          <ApplicationQuote
            quote="Using an EDGE Scrubber with a Melomine pad to clean rubber, marmoleum, linoleum or stone floors out-performs
Any other machine on the markets.  It can restore floors that folks think need to be replaced.  Zero3 chemical-free cleaning is beyond any GREEN specification and can sanitize floors to keep students safe and healthy.
 -KB"
          />
          <ApplicationQuote quote="FactoryCat floor scrubbers and burnishers are easily becoming the go to pieces of cleaning machinery for schools and universities everywhere. -JM" />
        </PageBody>
        <EducationImages />
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default EducationPage
